import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"
import Article from "../components/section/article";

export default function Services() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiServices.seo} slug={data.strapiServices.slug_it}  lang="it" currentId="2">
      <Article list={data.strapiServices.article} lang="it" />
    </Layout>
  );
}

const query = graphql`
  query {
    strapiServices {
      slug_it
      seo {
        meta_title_it
        meta_description_it
      }
      article {
        type
        subtext_it
        text_it
        button {
          label
          url
        }
      }
    }
  }
`;